import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { actions, asyncForEach, BeneficiaryGroup, FormDefinition, GroupedDDL } from '@roadmate/roadmate-common';
import { cilCheckCircle, cilGroup, cilPlus } from '@coreui/icons';
import { FireStoreService, MessengerService, ModalService, RMToasterService } from '@rm-services';
import { randomId } from '@rm-utils';
import { entryComponentList } from '@rm-modals';
import { ColComponent, RowComponent, TemplateIdDirective, WidgetStatCComponent } from '@coreui/angular';
import { IconDirective } from '@coreui/icons-angular';
import { ProgressComponent } from '@coreui/angular-pro';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Subscription } from 'rxjs';


type GroupWithCount = BeneficiaryGroup & {count?: number};
interface IGroup {
  product: string;
  groups: {
    group: GroupWithCount;
    count: number;
  }[]
}


@Component({
  selector: 'rm-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  standalone: true,
  imports: [
    ColComponent, RowComponent, IconDirective, 
    WidgetStatCComponent, ProgressComponent, TranslateModule, TemplateIdDirective,
    NgFor, ProgressSpinnerModule, NgClass, NgIf
  ]
})
export class GroupListComponent implements OnInit {
  @Input()
  standalone = true;
  @Output()
  selectGroupEmitter = new EventEmitter<GroupedDDL>();
  public groups: IGroup[] = [];
  public groupDef: FormDefinition;
  public settings: any;
  public loading = true;
  public icons = {
    cilGroup,
    cilPlus,
    cilCheckCircle
  }
  public totalUsers = 0;
  @Output()
  addGroupEmitter = new EventEmitter<void>();
  public selectedGroup: BeneficiaryGroup;
  private subs: Subscription[] = [];
  constructor(
    private fs: FireStoreService,
    private toast: RMToasterService,
    private msg: MessengerService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getGroups();
    this.msg.parcel.subscribe(async (parcel) => {
      if (parcel.action === actions.groupChange) {
        await this.getGroups();
      }
    });
  }

  private async getGroups() {
    this.loading = true;
    this.groups = [];
    try {
      
      const agentRef = this.fs.currentAgent.ref;
      const companyRef = this.fs.currentCompany.ref;
      if (!agentRef || !companyRef) {
        this.toast.showGenericError();
        return;
      }
      
      const allGroups: GroupWithCount[] = await this.fs.getGroups(agentRef, companyRef);
      await asyncForEach(allGroups, async (group) => {
        group.count = await this.fs.getGroupInviteesCount(agentRef, companyRef, group.ref ?? '') ?? 0;
      })
      allGroups.forEach(group => {
        const iGroup = this.groups.find(el => el.product === group.roadmateProduct);
        if (iGroup) {
          iGroup.groups.push({group, count: 0});
        } else {
          this.groups.push({product: group.roadmateProduct, groups: [{group, count: group.count ?? 0}]});
        }
      });
    } catch (e) {
      console.error('', e);
    } finally {
      this.loading = false;
    }
  }

  // private async setTable() {
  //   this.groupDef = this.fs.getObjectDefinitionByRef(ObjectDefList.BeneficiaryGroup);
  //   this.settings = await getTableSettings(this.groupDef, this.translate);
  //   this.loading = false;
  // }

  public addGroup() {
    this.addGroupEmitter.emit();
  }

  public selectGroup(product: string, data: BeneficiaryGroup) {
    if (!this.standalone) {
      this.selectedGroup = data;
      this.selectGroupEmitter.emit({
        label: product,
        value: product,
        ddl: [
          {
            label: data.name,
            value: data.ref ?? ''
          }
        ]
      });
      return;
    }
    const group = data
    const id = randomId();
    this.modalService.open({
      isOpen: true,
      component: entryComponentList.viewUsersInGroup,
      modalTitle: 'users_in_group',
      headerSuffix : group.name,
      dismissFooter: false,
      data: group.ref,
      id
    });
  }

  private async saveGroup(group: BeneficiaryGroup) {
    this.loading = true;
    try {
      const agentRef = this.fs.currentAgent.ref;
      const companyRef = this.fs.currentCompany.ref;
      if (!agentRef || !companyRef || !group.ref) {
        this.toast.showGenericError();
        return;
      }
      await this.fs.saveGroup(agentRef, companyRef, group);
      this.toast.showSuccessToast('Le groupe a bien été modifié');
    } catch (e) {
      console.error('', e);
      this.toast.showGenericError();
    } finally {
      this.loading = false;
    }
  }
}
