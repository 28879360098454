@if(!loading && groups.length) {
  <c-row *ngFor="let item of groups" class="mb-5 mt-5">
    <h2 class="mb-3">
      {{('PRODUCT_' + item.product) | translate}}
    </h2>
    <c-col sm="6" md="3" *ngFor="let g of item.groups" class="mb-2">
      <c-widget-stat-c
        [title]="g.count.toString() + ' ' + ('users' | translate)"
        [value]="g.group.name"
        class="rm-clickable"
        [ngClass]="{'selected': !standalone && selectedGroup?.ref === g.group.ref}"
        (click)="selectGroup(item.product, g.group)"
      >
      <ng-template cTemplateId="widgetIconTemplate">
        <svg [cIcon]="icons.cilGroup" height="40" title="Groupe"></svg>
        <svg [cIcon]="icons.cilCheckCircle" height="40" title="selected" *ngIf="!standalone && selectedGroup?.ref === g.group.ref"></svg>
      </ng-template>
      <ng-template cTemplateId="widgetProgressTemplate">
        <c-progress class="mt-3 mb-0" thin [value]="100 * g.count/totalUsers" color="primary" />
      </ng-template>
    </c-widget-stat-c>
    </c-col>
  </c-row>
} @else if (!loading && !groups.length) {
  <c-row>
    <c-col sm="12" class="center detached">
      <p>{{'no_groups_to_manage' | translate}}</p>
    </c-col>
  </c-row>

} @else {
  <c-row>
    <c-col sm="12" class="center detached">
      <p-progressSpinner ariaLabel="loading" />
    </c-col>
  </c-row>
}
